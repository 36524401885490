import {fetchBaseQuery} from '@reduxjs/toolkit/query';
import {createApi} from '@reduxjs/toolkit/query/react';
import {environment} from '@vin-decoder/environment';

import {V1NGetDecoderApiArg, V1NGetDecoderApiResponse} from './types/vinDecoderTypes';

export const vinDecoderApi = createApi({
  reducerPath: 'vinDecoder',
  baseQuery: fetchBaseQuery({
    baseUrl: environment.VIN_DECODER_URL,
  }),
  endpoints: (build) => ({
    v1NGetDecoder: build.query<V1NGetDecoderApiResponse, V1NGetDecoderApiArg>({
      query: (queryArg) => ({url: `/decoder/${queryArg.id}`}),
    }),
  }),
});

export const {useV1NGetDecoderQuery} = vinDecoderApi;
