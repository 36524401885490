import {fetchBaseQuery} from '@reduxjs/toolkit/query';
import {createApi} from '@reduxjs/toolkit/query/react';
import {environment} from '@vin-decoder/environment';

import {
  GetAllApiEnumsApiArg,
  GetAllApiEnumsApiResponse,
  GetApiMakeModelWithMakeApiArg,
  GetApiMakeModelWithMakeApiResponse,
} from './types/catalogueTypes';

export const catalogueApi = createApi({
  reducerPath: 'catalogue',
  baseQuery: fetchBaseQuery({
    baseUrl: environment.CATALOGUE_URL,
  }),
  endpoints: (build) => ({
    getAllApiEnums: build.query<GetAllApiEnumsApiResponse, GetAllApiEnumsApiArg>({
      query: (queryArg) => ({
        url: '/api/enum',
        params: {
          lang: queryArg.lang,
          order_by: queryArg.orderBy,
          vehicle_type: queryArg.vehicleType,
        },
      }),
    }),
    getApiMakeModelWithMake: build.query<
      GetApiMakeModelWithMakeApiResponse,
      GetApiMakeModelWithMakeApiArg
    >({
      query: (queryArg) => ({
        url: `/api/make-model/${queryArg.vehicleType}/${queryArg.make}`,
        params: {lang: queryArg.lang, logo_url: queryArg.logoUrl},
      }),
    }),
  }),
});

export const {useGetAllApiEnumsQuery, useGetApiMakeModelWithMakeQuery} = catalogueApi;
