import {
  createContext,
  createRef,
  ReactNode,
  RefObject,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import {isNotNil} from 'ramda';

import {debounce, noop} from 'shared';

interface Section {
  title: string;
  ref: RefObject<HTMLDivElement | null>;
}

export const NavigationContext = createContext<{
  sections: Section[];
  registerSection: (title: string) => RefObject<HTMLDivElement | null>;
  activeSection: number;
}>({
  sections: [],
  registerSection: noop,
  activeSection: 0,
});

export const useNavigation = () => useContext(NavigationContext);

export const NavigationContextProvider = ({children}: {children: ReactNode}) => {
  const sections = useRef<Section[]>([]);
  const [activeSection, setActiveSection] = useState(0);

  useEffect(() => {
    const handleScroll = debounce(() => {
      const scrollPosition = window.scrollY;
      const activeSection = sections.current
        .map((section) => section.ref)
        .findIndex((ref) =>
          isNotNil(ref?.current)
            ? ref.current.offsetTop + ref.current.clientHeight > scrollPosition
            : false
        );
      setActiveSection(activeSection);
    }, 50);

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const registerSection = (title: string) => {
    const section = sections.current.find((section) => section.title === title);

    if (isNotNil(section)) {
      return section.ref;
    } else {
      const ref = createRef<HTMLDivElement>();
      sections.current.push({title, ref});
      return ref;
    }
  };

  return (
    <NavigationContext.Provider
      value={{sections: sections.current, registerSection, activeSection}}
    >
      {children}
    </NavigationContext.Provider>
  );
};
